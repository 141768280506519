import React from 'react'

function OurServices({data}){
  const intendedText = []

  data[1].forEach((data) => {
    intendedText.push(<p>{data}</p>)
  })
  return (
    <div className = "ourServices" style={data[2]}>
      
        <div className="ourServicesDiv">
        {data[0] !== "" ? <h4>{data[0]}</h4> : ""}
        <br></br>
        {intendedText}
        </div>
       
    </div>
  )
}

export default OurServices