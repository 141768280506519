import './App.css';
import NavBar from './comp/NavBar';
import ContentLeft from './comp/ContentLeft';
import OurServices from './comp/OurServices';


function App() {
  return (
    <div className="App">
      
      <NavBar className="navBarElement"></NavBar>
      <div className="app">
      
    <div style={{"padding-top":"10%"}}></div>
        <ContentLeft className="contentLeftElement" data={["Kevin McHugh",require("./comp/1591806727538.jpg"),"",["Kevin McHugh is the Managing Consultant of Priory GRC Consulting Limited","He is an experienced Chief Risk Officer with three decades Board level international experience through the cycle in governance, compliance and risk management. With a proven track record in complex corporate environments, Kevin has acted as a Company Director and Leadership Team member, has chaired the Risk Management Committee of a credit institution, is a Fellow of the Institute of Chartered Accountants in Ireland and of the Chartered Institute of Securities and Investment","Kevin has lectured for the Institute of Chartered Accountants in Ireland and spoken at conferences in Ireland, the UK, Brussels and Amsterdam"]]}></ContentLeft>
  
        <a href="services" className="ourServicesElement"><OurServices className="ourServicesElement" data={["Our Services",["We have you covered by providing tailored services to meet your Governance, Risk and Compliance needs. We can provide end to end implementation or, if it works better for you, can work with your team including any other advisors that you use.","Please click here to explore our range of services"]]}></OurServices></a>
      </div>
    </div>
  );
}

export default App;
