import '../App.css';
import NavBar from './NavBar';
// import { Document, Page } from 'react-pdf';
// import {PDFViewer} from 'react'
// import { pdfjs } from 'react-pdf';
// import React, { useState } from 'react';
// // import file from "./Individual_Accountability_Framework.pdf"

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();




function Test({data}) {
  
    return (
      <div>
        
        <NavBar className="navBarElement"/>
        <div  style={{"padding-top":"10%"}}>
        <object alt="PDF not Loaded"
        data = {data}
        width="100%"
        height={window.screen.height-(window.screen.height * 0.2)}>
          <p>Unable to display PDF file. </p>
        </object>
        </div>
        </div>
        ); 
  }
  
export default Test;