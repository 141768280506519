import '../App.css';
import NavBar from './NavBar';
import ContentRight from './ContentRight';
import ContentLeft from './ContentLeft';
import content from './content';




function Services() {
    return (
      <div className="Services">
        
        <NavBar className="navBarElement"/>
        <div  style={{"padding-top":"10%"}}>
        
      </div> 
      {content.map((nav, index) => (<div>

           {index%2 === 0 ? <ContentRight data={[nav.title, require(`${nav.src}`), nav.title,nav.text]}/> : <ContentLeft data={[nav.title, require(`${nav.src}`), nav.title,nav.text]}/>}
            </div>     
           ))}
            
      </div>
    );
  }
  
  export default Services;