import React, {useState} from 'react'

//import {close, logo, menu} from "../assets"
function NavBar() {
    var navLinks = [
        {id:"/about-us",title:"About Us"},
        {id:"/why-us",title:"Why Us"},
        {id:"/services",title:"Services"},
        {id:"/news",title:"News"},
        {id:"/contact-us",title:"Contact Us"}
    ]

    let showNavBar = () => {
        if(document.getElementsByClassName('navBarList')[0].style.display === "flex")
        {
            document.getElementsByClassName('navBarList')[0].style.display = 'none'
        }
        else{
            document.getElementsByClassName('navBarList')[0].style.display = 'flex'
        }
    }
  return (
    <div className='buffer'>
    <div className = "navBar">
        <a href='/'><img href = "/" src= {require("./prioryGRClogo.PNG")} alt="prioryGRC" className="logo"></img>
        </a>
        <ul className="navBarList">
            {navLinks.map((nav, index) => (<li
            id={nav.id}
            className = "listItem">
                <a href={`${nav.id}`} style = {{row: index}}>{nav.title}</a>
            </li>
            ))}
        </ul>
        <ul className="hamburger" onClick={showNavBar}>
            <li></li>
            <li></li>
            <li></li>
        </ul>
        
    </div>
    </div>
  )
}

export default NavBar