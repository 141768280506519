import React from 'react'

function ContentRight({data}){
  const intendedText = []

  data[3].forEach((data) => {
    intendedText.push(<p>{data}</p>)
    intendedText.push(<br></br>)
  })
  return (
    <div className = "contentRight" style={data[4]}>
        <div className = "fade-in-image">
        <div className="contentLeftText" style={data[5]}>
        {data[2] !== "" ? <h4>{data[2]}</h4> : ""}
        <br></br>
    
        {intendedText}

        
        </div>
        <img alt={data[0]} src={`${data[1]}`} className="contentImg"></img>
        </div>
    </div>
  )
}

export default ContentRight