import React from 'react'

function ContentLeft({data}){
  const intendedText = []

  data[3].forEach((data) => {
    intendedText.push(<p>{data}</p>)
  })
  return (
    <div className = "contentLeft" style={data[4]}>
      <div className = "fade-in-image">
      <img alt={data[0]} src={`${data[1]}`} className="contentImg"></img>
        <div className="contentLeftText">
        {data[2] !== "" ? <div><h4 className='header'>{data[2]}</h4> <br></br></div>: ""}
        
        {intendedText}
        </div>
        </div>
    </div>
  )
}

export default ContentLeft