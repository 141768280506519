import '../App.css';
import NavBar from './NavBar';


function Contact() {
    return (
      <div>
        
        <NavBar className="navBarElement"/>
        <div  style={{"padding-top":"15%"}}>
        
         <div className = 'contact-us'>
          <div className = "contact-info">
            
            <b>Phone Number:</b> <p> +353 87 2857545</p>
            <br></br>
            <b>Email:</b> <p>kevin.mchugh@pgrc.ie</p>
          </div>
          <img alt = "Priory GRC Consulting" src = {require('./prioryGRClogo.PNG')} className = "contact-img"></img>
      </div>
        </div>
        </div>
        ); 
     
  }
  
  export default Contact;