import '../App.css';
import NavBar from './NavBar';
import Article from './Article';
import content from './articles';


function News() {
    let orderedContent = content.reverse()
    return (
      <div style={{"display": "flex", "flex-direction": "row"}}>
        
        <NavBar className="navBarElement"/>
        <div  style={{"padding-top": "13%"}}>
        
         <div className="news">  
        {orderedContent.map((nav, index) => {if(index === orderedContent.length-1){return (<div>
        <a href={nav.href}>
          <Article data={[nav.href, nav.title, nav.text,nav.link]}/>
        </a>
        </div>     
        )}})}
        
      </div>
        </div>
        </div>
        ); 
     
  }
  
  export default News;