import '../App.css';
import NavBar from './NavBar';
import ContentRight from './ContentRight';

function AboutUs() {
    return (
      <div className="AboutUs">
        
        <NavBar className="navBarElement"/>
        <div  style={{"padding-top":"10%"}}>
        
      </div> <ContentRight className="contentLeftElement" data={["Kevin McHugh",require("./1591806727538.jpg"),"About Us",["Priory GRC Consulting Limited is a boutique firm which advises clients on all aspects of Governance, Risk and Compliance","Kevin McHugh is the Managing Consultant of Priory GRC Consulting Limited","He is an experienced Chief Risk Officer with three decades Board level international experience through the cycle in governance, compliance and risk management. With a proven track record in complex corporate environments, Kevin has acted as a Company Director and Leadership Team member, has chaired the Risk Management Committee of a credit institution, is a Fellow of the Institute of Chartered Accountants in Ireland and of the Chartered Institute of Securities and Investment","Kevin has lectured for the Institute of Chartered Accountants in Ireland and spoken at conferences in Ireland, the UK, Brussels and Amsterdam"],[],{}]}/>

      </div>
    );
  }
  
  export default AboutUs;