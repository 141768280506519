import '../App.css';
import NavBar from './NavBar';
import ContentRight from './ContentRight';

function WhyUs() {
    return (
      <div className="whyUs">
        
        <NavBar className="navBarElement"/>
        
      <div style={{"padding-top":"10%"}}></div>
          <ContentRight className="contentLeftElement" data={["Kevin McHugh",require("./1591806727538.jpg"),"Why Us?",[' We always taking a proportionate risk- based  and tailored view on how best to help you', ' We provide solutions which are realistic and delivered by experienced consultants at a reasonable price  ', 'We implement agreed solutions and ensure there is knowledge transfer to you at the end of our work with you',  'Depending on the circumstances, we will deliver services directly or in conjunction with your teams.'],[],{}]}/>

      </div>
    );
  }
  
  export default WhyUs;