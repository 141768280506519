import '../App.css';


function Article({data}) {
    return (
        <section className="article">
            
            <div className="articleText">
            <a href={data[0]}>
            <h1>{data[1]}</h1>
            <p>{data[2]}</p>
            
            </a>
            </div>
        </section>
    );
  }
  
  export default Article;