
import App from './App';
import AboutUs from './comp/AboutUs';
import WhyUs from './comp/WhyUs';
import Services from './comp/Services';
import News from './comp/News';
import Contact from './comp/Contact';
import Test from './comp/test';
import articles from './comp/articles';

const page_list = [
    {
      path: "/",
      element: <App />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },{
      path: "/why-us",
      element: <WhyUs />,
    },{
      path: "/services",
      element: <Services />,
    },{
      path: "/news",
      element: <News />,
    },{
      path: "/contact-us",
      element: <Contact/>,
    }
  ]
  for(let i = 0; i<articles.length; i++)
  {
    var article = articles[i]
    page_list.push({path: article.href,element: <Test data={article.src}/>})
  }
    
  export default page_list;